<template>
  <section>
        <div class="box-title">
        <div class="qu-wrap ">
          <div class="qu-content ">
            <el-row :gutter="24" style="margin:0">
              <el-col :span="24"  class="qu-items" v-if="questions.length>0">
                <div class="qu-items-warp"> 
                  <section 
                  style="padding-bottom:30px;overflow:hidden"
                  class="qu-item"
                  v-for="(item, index) in questions"
                  :key="index"
                  :class="{nowEditing: curIndex === index && topicEditing, optEditing: curIndex === index}"
                >
                  <h2 style="background-color:#fff;border-bottom:1px solid #efefef;font-size:16px;font-weight:400">
                    <span :class="{quNum1:index<=8,quNum2:index>8}">{{`${index + 1}`}}</span>
                    <span class="qu-tit" v-if="item.type === '单选型'">单选型</span>
                    <span class="qu-tit" v-if="item.type === '多选型'">多选型</span>
                    <span class="qu-tit" v-if="item.type === '文本描述型'">文本描述型</span>
                    <span class="qu-tit" v-if="item.type === '日期型'">日期型（年月日）</span>
                  </h2>
                  <dl>
                    <dd><i style="color:red">*</i> 标题：</dd>
                    <dt>
                        <span class="qu-topic">{{ item.title }}</span>
                    </dt>
                    <dd>是否必填：</dd>
                    <dt>
                      <el-radio-group v-model="item.required">
                        <el-radio :label="true" disabled>是</el-radio>
                        <el-radio :label="false" disabled>否</el-radio>
                      </el-radio-group>
                    </dt>
                    <dd v-if="item.type === '单选型'">表现形式：</dd>
                    <dt v-if="item.type === '单选型'">
                      <el-radio-group v-model="item.selectType">
                        <el-radio label="单选" disabled>单选</el-radio>
                        <el-radio label="下拉" disabled>下拉</el-radio>
                      </el-radio-group>
                    </dt>
                  </dl>
                  <ul class="options-list" style="margin:0">
                    <li
                      style="overflow:hidden;height:100%"
                      v-for="(option, optIndex) in item.options"
                      :key="optIndex"
                      :class="{optionEditing: curOptIndex === optIndex}"
                    >
                      <span class="optionText" >{{ option }}</span>
                    </li>
                  </ul>
                </section>
                </div>               
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
import {
  getCommentColumnById
} from "../../api/api";
export default {
  name: "Edit",
  props:['id'],
  data() {
    return {
      index: "",
      questions: [],
      title: "",
      topic: "",
      optionText: "",
      curIndex: "",
      curOptIndex: "",
      topicEditing: false,
    };
  },

  created() {
    if (this.id) {
      this.getQA(this.id);
    } else {
      this.$message.error("无法访问");
      this.$emit('cancel')
    }
    this.setStore('QATemplate',this.questionTemplate)
  },
  methods: {
    getQA(commentId) {
      //通过id获取题型编辑
      this.questions = [];
      getCommentColumnById({ commentId: commentId }).then(res => {
        if (res.success) {
          this.questions = res.result;
        }
      });
    },

  },
  destroyed(){
    this.removeStore('QATemplate')
  },
  directives: {
    focus: {
      update: el => el.focus()
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/_Edit";
.box-title{
  padding: 0 !important;

  .qu-topic{
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding-left: 5px;
    font-size: 14px;
  }

  .optionText{
    float: left;
    height: 30px;
    line-height: 30px;
    padding: 0 10px ;
    font-size: 14px;
    // background-color: #f6f6f6;
    color: #666;
    margin-left: 100px;
  }
}
</style>
