<template>
  <section>
    <div class="box-title">
      <div class="qu-wrap">
        <div class="qu-content">
          <el-row :gutter="24" style="margin: 0">
            <el-col :span="6" style="padding: 0">
              <div class="add-box">
                <p @click="addType('单选型')">
                  <i class="iconfont icondanxuan-peizhi"></i>单选型
                </p>
                <p @click="addType('多选型')">
                  <i class="iconfont iconduoxuan-peizhi"></i>多选型
                </p>
                <p @click="addType('文本描述型')">
                  <i class="iconfont iconwenbenmiaoshu-peizhi"></i>文本描述型
                </p>
                <p @click="addType('日期型')">
                  <i class="iconfont iconriqixing-peizhi"></i>日期型（年月日）
                </p>
              </div>
            </el-col>
            <el-col
              :span="18"
              class="qu-items"
              v-if="questions.length > 0"
              style="padding:0 0 60px"
            >
              <div class="qu-items-warp">
                <section
                  style="padding-bottom: 20px; overflow: hidden"
                  class="qu-item"
                  v-for="(item, index) in questions"
                  :key="index"
                  :class="{
                    nowEditing: curIndex === index && topicEditing,
                    optEditing: curIndex === index,
                  }"
                >
                  <h2
                    style="
                      background-color: #fff;
                      border-bottom: 1px solid #efefef;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    <span :class="{ quNum1: index <= 8, quNum2: index > 8 }">{{
                      `${index + 1}`
                    }}</span>
                    <span class="qu-tit" v-if="item.type === '单选型'"
                      >单选型</span
                    >
                    <span class="qu-tit" v-if="item.type === '多选型'"
                      >多选型</span
                    >
                    <span class="qu-tit" v-if="item.type === '文本描述型'"
                      >文本描述型</span
                    >
                    <span class="qu-tit" v-if="item.type === '日期型'"
                      >日期型（年月日）</span
                    >
                    <div class="operat-list" @click="delQuestion(index)">
                      <i class="iconfont iconguanbi"></i>
                    </div>
                  </h2>
                  <dl>
                    <dd>标题</dd>
                    <dt>
                      <p
                        class="qu-titName"
                        @click="
                          (curIndex = index),
                            (curOptIndex = ''),
                            (topicEditing = true)
                        "
                      >
                        <span class="qu-topic">{{ item.title }}</span>
                        <input
                          type="text"
                          v-focus
                          maxlength="35"
                          v-model="topic"
                          @focus="topic = item.title"
                          @blur="(curIndex = ''), (topic = '')"
                          @keyup.esc="cancelTopicEdit()"
                          @keyup.enter="doneTopicEdit(index)"
                        />
                      </p>
                    </dt>
                    <dd>是否必填</dd>
                    <dt>
                      <el-radio-group v-model="item.required">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </dt>
                    <dd v-if="item.type === '单选型'">表现形式</dd>
                    <dt v-if="item.type === '单选型'">
                      <el-radio-group v-model="item.selectType">
                        <el-radio label="单选">单选</el-radio>
                        <el-radio label="下拉">下拉</el-radio>
                      </el-radio-group>
                    </dt>
                  </dl>
                  <ul class="options-list clearfix">
                    <li
                      style="overflow: hidden; height: 100%; margin: 0"
                      v-for="(option, optIndex) in item.options"
                      :key="optIndex"
                      :class="{ optionEditing: curOptIndex === optIndex }"
                    >
                      <span
                        style="margin-bottom: 5px"
                        class="optionText"
                        @click="
                          (curIndex = index),
                            (curOptIndex = optIndex),
                            (topicEditing = false)
                        "
                        >{{ option }}</span
                      >
                      <input
                        style="
                          margin-left: 100px;
                          font-size: 16px;
                          width: 400px;
                          margin-bottom: 5px;
                          float: left;
                        "
                        type="text"
                        v-focus
                        maxlength="30"
                        v-model="optionText"
                        @focus="optionText = option"
                        @blur="(curIndex = ''), (optionText = '')"
                        @keyup.esc="cancelOptionEdit()"
                        @keyup.enter="doneOptionEdit(index, optIndex)"
                      />
                      <!-- 选项操作 -->
                      <ul
                        class="opt-ctrl"
                        style="padding-top: 5px; float: left; overflow: hidden"
                      >
                        <li
                          v-if="optIndex !== 0"
                          @click="moveUp(optIndex, item.options)"
                          style="padding-left: 5px"
                        >
                          上移
                        </li>
                        <li
                          style="padding-left: 5px"
                          v-if="optIndex !== item.options.length - 1"
                          @click="moveDown(optIndex, item.options)"
                        >
                          下移
                        </li>
                        <li
                          @click="delOption(optIndex, item.options)"
                          style="padding-left: 5px"
                        >
                          删除
                        </li>
                      </ul>
                    </li>
                    <!-- 设置添加操作 -->
                    <li
                      class="opt-add"
                      v-if="item.options.length"
                      @click="addOption(item.options)"
                    >
                      + 添加
                    </li>
                  </ul>
                </section>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <footer class="footerBtn">
                <el-button type="default" size="mini" @click="resetForm">取消</el-button>
                <el-button type="primary" size="mini" style="margin-right: 15px;" @click="saveData">保存</el-button>
              </footer>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  addOrEditCommentColumn,
  getCommentColumnById,
} from "../../api/api";
export default {
  name: "Edit",
  props: ["id"],
  data() {
    return {
      index: "",
      quData: {
        commentId: 0,
        commentColumnList: [],
      },
      questions: [],
      questionTemplate: {
        //题型模板
        单选型: {
          id: 0,
          type: "单选型",
          title: "点击此处填写标题",
          options: ["选项1", "选项2"],
          required: true,
          selectType: "单选",
          version: 0,
        },
        多选型: {
          id: 0,
          type: "多选型",
          title: "点击此处填写标题",
          options: ["选项1", "选项2"],
          required: true,
          selectType: 0,
          version: 0,
        },
        文本描述型: {
          id: 0,
          type: "文本描述型",
          title: "点击此处填写标题",
          options: [],
          required: true,
          selectType: 0,
          version: 0,
        },
        日期型: {
          id: 0,
          type: "日期型",
          options: [],
          title: "点击此处填写标题",
          required: true,
          selectType: 0,
          version: 0,
        },
      },
      date: "",
      title: "",
      _title: "",
      topic: "",
      _topic: "",
      optionText: "",
      _optionText: "",
      curIndex: "",
      curOptIndex: "",
      promptText: "",
      iterator: {},
      isAdding: false,
      titleEditing: false,
      topicEditing: false
    };
  },

  created() {
    if (this.id) {
      this.getQA(this.id);
    } else {
      this.$message.error("无法访问");
      this.$emit('cancel')
    }
    this.setStore("QATemplate", this.questionTemplate);
  },
  methods: {
    resetForm(){
      this.$emit('cancel')
    },
    getQA(commentId) {
      //通过id获取题型编辑
      this.questions = [];
      getCommentColumnById({ commentId: commentId }).then((res) => {
        if (res.success) {
          this.questions = res.result;
        }
      });
    },

    cancelTopicEdit() {
      this.topicEditing = false;
      //this.topic = this._topic;
    },

    cancelOptionEdit() {
      this.curOptIndex = "";
      this.optionText = this._optionText;
    },

    doneTopicEdit(index) {
      this.topicEditing = false;
      this.questions[index].title = this.topic;
    },

    doneOptionEdit(index, optIndex) {
      this.curOptIndex = "";
      this.questions[index].options[optIndex] = this.optionText;
    },

    switchItem(index, array) {
      this.optIndex = "";
      this.curIndex = "";

      let arr = array.splice(index, 2);
      array.splice(index, 0, ...arr.reverse());
    },

    moveUp(index, array) {
      this.switchItem(index - 1, array);
    },

    moveDown(index, array) {
      this.switchItem(index, array);
    },

    errorPrompt(text) {
      this.iterator = null;
      this.$alert(text, "提示", {
        confirmButtonText: "确定",
        customClass:"altCls",
        type: "warning",
        callback: (action) => {},
      });
    },

    delQuestion(index) {
      if (this.questions.length <= 1) {
        this.errorPrompt(`备注至少一个题型！`);
        return;
      }
      this.questions.splice(index, 1);
    },

    delOption(index, options) {
      if (options.length <= 2) {
        this.errorPrompt(`至少两个选项`);
        return;
      }
      options.splice(index, 1);
    },

    addOption(options) {
      options.push(`请编辑选项内容`);
    },
    addType(type) {
      let QATemplate = JSON.parse(this.getStore("QATemplate"));
      this.questions.push(QATemplate[type]);
    },
    saveData() {
      if (this.questions.length < 1) {
        this.errorPrompt(`至少一个问题！`);
        return;
      }
      if (!this.questions.every((item) => item.title != "点击此处填写标题")) {
        this.errorPrompt(`有标题没有填写，请检查`);
        return false;
      }

      this.quData.commentId = this.id;
      this.quData.commentColumnList = [...this.questions];
      addOrEditCommentColumn(this.quData).then((res) => {
        if (res.success) {
          this.$alert("配置备注方案成功", "提示", {
            confirmButtonText: "确定",
            customClass:"altCls",
            type: "success",
          })
            .then(() => {
              this.$emit('cancel')
            })
            .catch(() => {
              this.$emit('cancel')
            });
        }
      });
    },
  },
  destroyed() {
    this.removeStore("QATemplate");
  },
  directives: {
    focus: {
      update: (el) => el.focus(),
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/_Edit";
.box-title {
  padding: 0 !important;

  .ctrl-part {
    margin: 0 auto;
  }

  .qu-topic {
    display: inline-block;
    width: 412px;
    height: 30px;
    line-height: 30px;
    padding-left: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }

  .optionText {
    float: left;
    width: 412px;
    height: 30px;
    line-height: 30px;
    padding-left: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-left: 100px;
  }

  .options-list {
    .opt-add {
      float: left;
      width: 412px;
      height: 30px;
      line-height: 30px;
      padding-left: 5px;
      font-size: 14px;
      border: 1px dashed #ddd;
      box-sizing: border-box;
      margin-top: 0;
      margin-left: 100px;
      text-align: center;
      cursor: pointer;
      color: #ccc;
      &:hover {
        border-color: #ff3f3f;
        color: #ff3f3f;
      }
    }
  }
  .footerBtn {
    position: fixed;
    height: 50px;
    z-index: 6;
    line-height: 50px;
    bottom: 20px;
    width: calc(100% - 40px);
    border-top: 1px solid #e5e5e5;
    left: 20px;
    text-align: right;
    background-color: #f0f0f0;
  }
}
</style>
