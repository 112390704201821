<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div class="box-title" style="padding: 20px 0; border: 1px #e5e5e5 solid">
      <el-row>
        <el-form
          @submit.native.prevent
          :model="filter"
          ref="filter"
          label-width="105px"
        >
          <el-form-item label="备注方案名称：" class="mgl10 fl" prop="name">
            <el-input
              style="width: 240px"
              v-model="filter.name"
              placeholder="请输入备注方案名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注类型：" class="fl" prop="type">
            <el-select
              v-model="filter.type"
              placeholder="请选择备注类型"
              clearable
              style="width: 240px"
            >
              <el-option-group
                v-for="group in selectOpts"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item
            style="text-align: right; margin-left: 20px"
            label-width="0"
            class="fl"
          >
            <el-button size="small" type="primary" @click="submitForm('filter')"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-col>
          <el-button
            type="default"
            class="mgl10"
            plain
            size="mini"
            @click="addNew"
          >
            <i class="el-icon-plus"></i> 添加备注方案
          </el-button>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="ListData"
            class="tabBorder"
            v-loading="listLoading"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="name"
              label="备注方案名称"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="status"
              label="备注方案状态"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="type"
              label="备注类型"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creatorName"
              label="创建者"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="createdTime"
              label="创建时间"
              align="left"
              width="160"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              align="left"
              width="150"
            >
              <template slot-scope="scope">
                <span
                  @click="handleEdit(scope.row.id)"
                  class="tabHref"
                  v-if="scope.row.status == '未启用'"
                  >编辑</span
                >
                <el-divider
                  v-if="scope.row.status == '未启用'"
                  direction="vertical"
                ></el-divider>
                <span
                  @click="handleConfig(scope.row.id)"
                  class="tabHref"
                  v-if="scope.row.status == '未启用'"
                  >配置</span
                >
                <el-divider
                  v-if="scope.row.status == '未启用'"
                  direction="vertical"
                ></el-divider>
                <span
                  @click="handleCheck(scope.row.id)"
                  class="tabHref"
                  v-if="scope.row.status != '未启用'"
                  >查看</span
                >
                <el-divider
                  v-if="scope.row.status != '未启用'"
                  direction="vertical"
                ></el-divider>
                <span
                  class="tabHref"
                  @click="
                    handleStar(
                      scope.row.id,
                      scope.row.status == '未启用'
                        ? '启用'
                        : scope.row.status == '停用'
                        ? '启用'
                        : '停用'
                    )
                  "
                  >{{
                    scope.row.status == "未启用"
                      ? "启用"
                      : scope.row.status == "停用"
                      ? "启用"
                      : "停用"
                  }}</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="135px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="备注方案名称：" prop="name">
              <el-input
                v-model.trim="createDate.name"
                maxlength="20"
                placeholder="请不要输入超过20个字"
                style="width: 80%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注对应类型：" prop="type">
              <el-select
                v-model="createDate.type"
                placeholder="请选择对应类型"
                style="width: 80%"
                clearable
              >
                <el-option-group
                  v-for="group in createOpts"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="
            dialogVisible = false;
            resetForm('createDate');
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveLoading"
          @click="save('createDate')"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-drawer
      :visible.sync="drawerDialogVisable"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
    >
      <div slot="title" class="drawerTitle">{{ titleName }}</div>
      <marktempConfig
        v-if="drawerDialogVisable && titleName == '配置'"
        :id="id"
        @cancel="drawerDialogVisable = false"
      ></marktempConfig>
      <Checkmarks
        v-if="drawerDialogVisable && titleName == '查看配置'"
        :id="id"
      ></Checkmarks>
    </el-drawer>
  </section>
</template>
<script>
import {
  getPageComment,
  addOrEditComment,
  deleteComment,
  changeStatusColumn,
  getCommentById,
} from "../../api/api";
import marktempConfig from "../../components/markTemp/Configremarks.vue";
import Checkmarks from "../../components/markTemp/Checkmarks.vue";
export default {
  components: { marktempConfig, Checkmarks },
  data() {
    return {
      ListData: [],
      selectOpts: [],
      saveLoading: false,
      id: null,
      titleName: "",
      drawerDialogVisable: false,
      createOpts: [],
      options: [
        {
          label: "客户管理",
          options: [
            {
              label: "客户沟通",
            },
            {
              label: "客户拜访",
            },
          ],
        },
        {
          label: "人才管理",
          options: [
            {
              label: "候选人沟通",
            },
            {
              label: "其他",
            },
          ],
        },
        {
          label: "项目管理",
          options: [
            {
              label: "项目信息",
            },
            {
              label: "候选人反馈",
            },
          ],
        },
        {
          label: "招聘管理",
          options: [
            {
              label: "加入项目",
            },
            {
              label: "简历推荐",
            },
            {
              label: "客户面试",
            },
            {
              label: "Offer",
            },
            {
              label: "背景调查",
            },
            {
              label: "入职",
            },
            {
              label: "成功",
            },
            {
              label: "失败",
            },
            {
              label: "内部面试",
            },
            {
              label: "F2F面试",
            },
          ],
        },
      ],
      titName: "",
      listLoading: false,
      dialogVisible: false,
      createDate: {
        id: "",
        name: "",
        type: "",
        version: 0,
      },
      filter: {
        name: "",
        type: null,
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写备注方案名称",
          },
        ],
        type: [
          {
            required: true,
            trigger: "change",
            message: "请选择",
          },
        ],
      },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
    };
  },
  watch: {
    drawerDialogVisable(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  methods: {
    submitForm() {
      this.pagination.pageNo = 1;
      this.loadList();
    },
    addNew() {
      this.titName = "新增";
      this.dialogVisible = true;
      this.createOpts = this.options;
      this.createDate.id = "";
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          addOrEditComment(this.createDate).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
              this.resetForm(formName);
              this.dialogVisible = false;
            }
            this.saveLoading = false;
          });
        }
      });
    },
    handleStar(id, type) {
      //启用停用
      this.$confirm("确认 " + type + " 该备注方案？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          changeStatusColumn({ id: id, status: type }).then((res) => {
            if (res.success) {
              this.$message.success("状态" + res.message);
              this.loadList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleConfig(rid) {
      //配置
      this.titleName = "配置";
      this.drawerDialogVisable = true;
      this.id = rid;
    },
    handleCheck(rid) {
      //查看
      this.titleName = "查看配置";
      this.drawerDialogVisable = true;
      this.id = rid;
    },
    handleDel(rid) {
      //删除
      this.$confirm("确认删除该配置？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          deleteComment({ id: rid }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleEdit(rid) {
      this.titName = "编辑";
      this.dialogVisible = true;
      this.createOpts = this.options;
      this.createDate.id = rid;
      getCommentById({ id: rid }).then((res) => {
        if (res.success) {
          this.createDate.name = res.result.name;
          this.createDate.type = res.result.type;
          this.createDate.version = res.result.version;
        }
      });
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      getPageComment({
        name: this.filter.name,
        type: this.filter.type,
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: true,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
  },
  created() {
    this.loadList();
    this.selectOpts = this.options;
  },
};
</script>